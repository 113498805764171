/* Common Imports */

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

/* Component Imports */

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Link,
  Typography,
  TextField,
  Button,
  Checkbox,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import MuiIconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

/* Action Imports */

// import { fetchLeads } from "../redux-components/Actions";

/* Redux Imports */

// import { connect } from "react-redux";

/* Data Imports */

import AppConfig from "../../AppConfig";

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: "1rem",
    width: "100%",
  },
  heading: {
    color: "#0FCA56",
    // background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent",
    fontWeight: "700",
    fontSize: "2.125rem",
    lineHeight: "3.1875rem",
    margin: "0rem 0rem 0.5rem 0rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.25rem",
    },
  },
  textField: {
    width: "100%",
    margin: "0rem 0rem 2rem 0rem",
  },
  // textFieldOtp: {
  //   width: "100%",
  //   margin: "0rem 0rem 2rem 0rem",
  // },
  // textFieldOtpHidden: {
  //   width: "100%",
  //   margin: "0rem 0rem 2rem 0rem",
  //   display: "none",
  // },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  radioBtn: {
    color: "default",
  },
  checkboxRow: {
    marginLeft: "0.5rem",
    paddingBottom: "1rem",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.up("lg")]: {
      alignItems: "center",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  grey: {
    fontSize: "0.9rem",
    fontColor: "#202020",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.85rem",
    },
  },
  link: {
    fontSize: "0.9rem",
    color: "#037DBB",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.85rem",
    },
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    padding: "1rem 2rem 1rem 2rem",
  },
  dialogActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    padding: "1rem",
    margin: "0.5rem 0rem 0.5rem 0rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      justifyContent: "center",
    },
  },
  primaryButton: {
    margin: "0rem 1rem 0rem 0rem",
    background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    color: "#FFFFFF",
    borderRadius: "0rem",
    borderWidth: "0px",
    fontWeight: "500",
    boxShadow: "none",
    padding: "0.5rem 1rem",
    "&:hover": {
      background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
      color: "#FFFFFF",
      textDecoration: "underline",
      textUnderlineOffset: "3px",
      boxShadow: "none",
    },
    "&:disabled": {
      background: "#ececec",
      color: "##88888a",
      border: "solid 1px #ececec",
    },
    [theme.breakpoints.down("xs")]: {
      width: "69%",
      margin: "0rem 0rem 1rem 0rem",
    },
  },
  cancelButton: {
    margin: "0rem 1rem 0rem 0rem",
    background: "#FFFFFF",
    color: "#0FCA56",
    borderRadius: "0rem",
    border: "solid 1px #0FCA56",
    borderWidth: "2px",
    fontWeight: "500",
    boxShadow: "none",
    "&:hover": {
      background: "#FFFFFF",
      textDecoration: "underline",
      boxShadow: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "69%",
      margin: "0rem 0rem 0rem 0rem",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const HomepageDownloadModal = (props) => {
  const classes = useStyles();

  // const [whatsapp, setWhatsapp] = React.useState(false);

  const [loadingSnackbar, setLoadingSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  // const [otperrorSnackbar, setOtpErrorSnackbar] = React.useState(false);
  // const [whatsapp, setWhatsapp] = React.useState(false);

  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [atc, setAtc] = React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [brochureURL, setBrochureURL] = React.useState(
    "/pdf/sentient-developers-brochure.pdf"
  );
  // const [otp, setOtp] = React.useState("");
  // const [channel, setChannel] = React.useState("sms");
  // const [otpShow, setOtpShow] = React.useState(false);
  // const [otpDisable, setOtpDisable] = React.useState(false);

  /* Handle Channel Change */
  // const handleChange = (event) => {
  //   setChannel(event.target.value);
  // };

  /* Handle Brochure Change */
  const handleBrochureChange = (event) => {
    setBrochureURL(event.target.value);
  };

  // const checkBtnDisabled = () => {
  //   if (
  //     name !== undefined &&
  //     phone !== undefined &&
  //     phone.match(/^[6-9]\d{9}$/) &&
  //     atc == false
  //   ) {
  //     setBtnDisabled(false);
  //   } else if (
  //     name == undefined ||
  //     phone == undefined ||
  //     phone.match(/^[6-9]\d{9}$/) ||
  //     atc == true
  //   ) {
  //     setBtnDisabled(true);
  //   }
  // };

  React.useEffect(() => {
    if (
      name !== (undefined || "") &&
      phone !== (undefined || "") &&
      phone.match(/^[6-9]\d{9}$/) &&
      atc === true
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [name, phone, atc]);

  /* Submit OTP */
  // const submitOtp = () => {
  //   setLoadingSnackbar(true);
  //   setOtpDisable(true);
  //   fetch(
  //     AppConfig.apiUrlPrefix +
  //       "verify/verify-otp?phone=" +
  //       encodeURIComponent(phone) +
  //       "&code=" +
  //       encodeURIComponent(otp) +
  //       "&channel=" +
  //       encodeURIComponent(channel),
  //     { cache: "no-store" }
  //   )
  //     .then((res) => res.json())
  //     .then((j) => {
  //       setLoadingSnackbar(false);
  //       setOtp("");
  //       if (j.status === "approved") {
  //         downloadButtonClickListener();
  //         setSuccessSnackbar(true);
  //         if (typeof props.submitCallback === "function") {
  //           props.submitCallback(j);
  //         }
  //       } else {
  //         setOtpErrorSnackbar(true);
  //       }
  //       setOtpDisable(false);
  //       setOtpShow(false);
  //       if (typeof submitCallback === "function") submitCallback(j);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setOtp("");
  //       setLoadingSnackbar(false);
  //       setErrorSnackbar(true);
  //       setOtpDisable(false);
  //       setOtpShow(true);
  //       if (typeof submitCallback === "function") submitCallback(error);
  //       /* TODO: Send Slack message with error */
  //     });
  // };

  /* Submit Form */
  const submitForm = () => {
    // if (otpShow) {
    //   submitOtp();
    // } else {
    // setOtpShow(true);
    const reqURL = encodeURI(
      AppConfig.apiUrlPrefix +
        "store-download-lead?name=" +
        name +
        "&phone=" +
        phone +
        "&email=" +
        email +
        "&agree=" +
        atc +
        // "&whatsapp=" +
        // whatsapp +
        "&source=" +
        (props.source ? props.source : "unknown") +
        "&channel=" +
        "none"
    );
    setLoadingSnackbar(true);
    fetch(reqURL)
      .then((res) => {
        res
          .json()
          .then((data) => {
            console.log(data);
            if (data.status === "success") {
              setLoadingSnackbar(false);
              setSuccessSnackbar(true);
              downloadButtonClickListener();
            } else {
              setLoadingSnackbar(false);
              setErrorSnackbar(true);
            }
            // fetch(
            //   AppConfig.apiUrlPrefix +
            //     "verify/send-otp?phone=" +
            //     encodeURIComponent(phone) +
            //     "&channel=" +
            //     encodeURIComponent(channel),
            //   { cache: "no-store" }
            // )
            //   .then((res) => res.json())
            //   .then((j) => {
            //     setLoadingSnackbar(false);
            //     if (typeof submitCallback === "function") submitCallback(j);
            /* TODO: Send Slack message with lead */
          })
          .catch((error) => {
            console.log(error);
            setLoadingSnackbar(false);
            setErrorSnackbar(true);
            if (typeof submitCallback === "function") {
              submitCallback(error);
            }
            /* TODO: Send Slack message with error */
          });
      })
      // .catch((error) => {
      //   console.log(error);
      //   setLoadingSnackbar(false);
      //   setErrorSnackbar(true);
      //   if (typeof submitCallback === "function") submitCallback(error);
      //   /* TODO: Send Slack message with error */
      // });
      // })
      .catch((error) => {
        console.log(error);
        setLoadingSnackbar(false);
        setErrorSnackbar(true);
        if (typeof submitCallback === "function") submitCallback(error);
        /* TODO: Send Slack message with error */
      });
    // }
  };

  const handleLoadingSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setLoadingSnackbar(false);
  };
  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessSnackbar(false);
  };
  const handleErrorSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbar(false);
  };
  // const handleOtpErrorSnackbarClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOtpErrorSnackbar(false);
  // };

  const downloadButtonClickListener = () => {
    const link = document.createElement("a");
    link.href = brochureURL;
    link.download = brochureURL.slice(5);
    link.dispatchEvent(new MouseEvent("click"));
  };

  return (
    <React.Fragment>
      <Snackbar
        open={loadingSnackbar}
        autoHideDuration={3000}
        onClose={handleLoadingSnackbarClose}
      >
        <Alert onClose={handleLoadingSnackbarClose} severity="info">
          Sending information...
        </Alert>
      </Snackbar>
      <Snackbar
        open={successSnackbar}
        autoHideDuration={3000}
        onClose={handleSuccessSnackbarClose}
      >
        <Alert onClose={handleSuccessSnackbarClose} severity="success">
          Thanks, {name}! Your PDF will download now.
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={handleErrorSnackbarClose}
      >
        <Alert onClose={handleErrorSnackbarClose} severity="error">
          Oops! Something seems to have gone wrong!
        </Alert>
      </Snackbar>
      {/* <Snackbar
        open={otperrorSnackbar}
        autoHideDuration={3000}
        onClose={handleOtpErrorSnackbarClose}
      >
        <Alert onClose={handleOtpErrorSnackbarClose} severity="error">
          Oops! OTP is invalid please try again !
        </Alert>
      </Snackbar> */}
      <Dialog
        className={classes.modal}
        onClose={() => {
          setName("");
          setPhone("");
          setAtc(false);
          props.onClose();
        }}
        aria-labelledby="brochure-download-modal"
        open={props.open}
        maxWidth="sm"
      >
        <DialogTitle id="download-modal-title" onClose={props.onClose}>
          <Typography variant="h5" component="p" className={classes.heading}>
            Download Brochure
          </Typography>
          <MuiIconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.onClose}
          >
            <CloseIcon />
          </MuiIconButton>
        </DialogTitle>
        <form method="GET" id="cf">
          <DialogContent dividers className={classes.dialogContent}>
            {/* Name */}
            <TextField
              id="name"
              name="name"
              label="Name"
              required={true}
              type="text"
              className={classes.textField}
              onChange={(event) => {
                setName(event.target.value);
                // checkBtnDisabled();
              }}
              // onBlur={(event) => {
              //   checkBtnDisabled();
              // }}
              // onFocus={(event) => {
              //   checkBtnDisabled();
              // }}
              // disabled={otpShow}
            />

            {/* Phone */}
            <TextField
              id="phone"
              name="phone"
              label="Phone Number (10 digit)"
              required={true}
              type="text"
              className={classes.textField}
              onChange={(event) => {
                setPhone(event.target.value);
                // checkBtnDisabled();
              }}
              // onBlur={(event) => {
              //   checkBtnDisabled();
              // }}
              // onFocus={(event) => {
              //   checkBtnDisabled();
              // }}
              // disabled={otpShow}
            />

            {/* Choose Brochure */}
            <FormControl>
              <FormLabel>Select brochure to download</FormLabel>
              <RadioGroup
                aria-label="/pdf/sentient-developers-brochure.pdf"
                name="select-brochure"
                className={classes.radioGroup}
              >
                <FormControlLabel
                  value="/pdf/sentient-developers-brochure.pdf"
                  control={
                    <Radio
                      color="primary"
                      checked={
                        brochureURL === "/pdf/sentient-developers-brochure.pdf"
                      }
                      onChange={handleBrochureChange}
                      // disabled={otpShow}
                    />
                  }
                  label="Sentient Developers"
                  // onBlur={(event) => {
                  //   checkBtnDisabled();
                  // }}
                  // onFocus={(event) => {
                  //   checkBtnDisabled();
                  // }}
                />
                <FormControlLabel
                  value="/pdf/heavie-eden-brochure.pdf"
                  control={
                    <Radio
                      color="primary"
                      checked={brochureURL === "/pdf/heavie-eden-brochure.pdf"}
                      onChange={handleBrochureChange}
                      // disabled={otpShow}
                    />
                  }
                  label="Heavie Eden by Sentient"
                  // onBlur={(event) => {
                  //   checkBtnDisabled();
                  // }}
                  // onFocus={(event) => {
                  //   checkBtnDisabled();
                  // }}
                />
              </RadioGroup>
            </FormControl>

            {/* Send OTP */}
            {/* <FormControl>
              <FormLabel>Send One Time Password (OTP) via</FormLabel>
              <RadioGroup
                aria-label="sms"
                name="radio-buttons-group"
                className={classes.radioGroup}
              >
                <FormControlLabel
                  value="sms"
                  control={
                    <Radio
                      color="primary"
                      checked={channel === "sms"}
                      onChange={handleChange}
                      disabled={otpShow}
                    />
                  }
                  label="SMS"
                  // onBlur={(event) => {
                  //   checkBtnDisabled();
                  // }}
                  // onFocus={(event) => {
                  //   checkBtnDisabled();
                  // }}
                />
                <FormControlLabel
                  value="call"
                  control={
                    <Radio
                      color="primary"
                      checked={channel === "call"}
                      onChange={handleChange}
                      disabled={otpShow}
                    />
                  }
                  label="Call"
                  // onBlur={(event) => {
                  //   checkBtnDisabled();
                  // }}
                  // onFocus={(event) => {
                  //   checkBtnDisabled();
                  // }}
                />
                <FormControlLabel
                  value="whatsapp"
                  control={
                    <Radio
                      color="primary"
                      checked={channel === "whatsapp"}
                      onChange={handleChange}
                      disabled={otpShow}
                    />
                  }
                  label="WhatsApp"
                  // onBlur={(event) => {
                  //   checkBtnDisabled();
                  // }}
                  // onFocus={(event) => {
                  //   checkBtnDisabled();
                  // }}
                />
              </RadioGroup>
            </FormControl> */}

            {/* Enter OTP  */}
            {/* <TextField
              id="otp"
              name="otp"
              label="One Time Password (OTP)"
              required={false}
              type="number"
              className={
                otpShow ? classes.textFieldOtp : classes.textFieldOtpHidden
              }
              onChange={(event) => {
                setOtp(event.target.value);
              }}
              value={otp}
              disabled={otpDisable}
            /> */}

            {/* Agree to whatsapp */}
            {/* <div className={classes.checkboxRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ padding: "0rem 0.5rem 0rem 0rem" }}
                    checked={whatsapp}
                    onChange={(event) => {
                      setWhatsapp(!whatsapp);
                    }}
                    color="primary"
                    value={whatsapp}
                    name="whatsapp"
                    disabled={otpShow}
                  />
                }
                label={
                  <span className={classes.grey}>
                    I would like to receive updates on WhatsApp
                  </span>
                }
              />
            </div> */}

            {/* Agree to T&C */}
            <div className={classes.checkboxRow}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ padding: "0rem 0.5rem 0rem 0rem" }}
                    checked={atc}
                    onChange={(event) => {
                      setAtc(!atc);
                      // checkBtnDisabled();
                    }}
                    color="primary"
                    value={atc}
                    name="agree"
                    // disabled={otpShow}
                  />
                }
                label={
                  <span>
                    <span className={classes.grey}>
                      I have read and agree with the{" "}
                    </span>
                    <Link
                      href="/terms"
                      target="_blank"
                      aria-label="Terms of Service"
                      title="Terms of Service"
                      rel="noopener"
                    >
                      Terms of Service{" "}
                    </Link>
                    and{" "}
                    <Link
                      href="/privacy"
                      target="_blank"
                      aria-label="Privacy Policy"
                      title="Privacy Policy"
                      rel="noopener"
                    >
                      Privacy Policy
                    </Link>
                  </span>
                }
              />
            </div>
          </DialogContent>

          <DialogActions className={classes.dialogActions} disableSpacing>
            <Button
              variant="contained"
              label="Close"
              onClick={props.onClose}
              className={classes.cancelButton}
            >
              Close
            </Button>
            <Button
              variant="contained"
              title="Submit"
              onClick={submitForm}
              disabled={btnDisabled}
              rel="noopener"
              className={classes.primaryButton}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

// function mapStateToProps(state) {
//   return {
//     leads: state.leads,
//   };
// }

// const mapDispatchToProps = {
//   fetchLeads,
// };

export default HomepageDownloadModal;
